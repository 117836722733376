import React, { useState } from "react";
import logo from "../../images/logo.png";
import "../landingPage/LandingPage.css";
import googleTop from "../../images/play.png";
import appTop from "../../images/app.png";
import handImage from "../../images/handImage.png";
import mobileImg1 from "../../images/mob-1.png";
import mobileImg2 from "../../images/mob-2.png";
import bottomGoogle from "../../images/play.png";
import bottomApp from "../../images/app.png";


import { ContactDetails } from "../contact/contact.js";

export const LandingPage = () => {
  const [showContactDetails, setShowContactDetails] = useState(false);

  const toggleContactDetails = () => {
    setShowContactDetails(!showContactDetails);
  };
 

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center w-100 ">
            <div>
              <img src={logo} alt="Bootstrap Logo" className="logo" />
              <button
                className="contact_us"
                type="button"
                onClick={toggleContactDetails}
              >
                Contact-us
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-md-6 order-md-2 step_container">
            <img src={handImage} className="hand_img" alt="HandPicture"></img>
          </div>
          <div class="col-md-6 order-md-1 col-lg-8">
            <div className="step_content_container">
              <div className="step-title">
                <p>Step into the heart</p>
              </div>
              <div className="step-text">
                of wholesomeness with our diverse array of grains, fostering
                vitality and wellness in every meal.
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.demandharvest.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={googleTop}
                    className="img-fluid googlePlayTop"
                    alt="Google Play"
                  ></img>
                </a>
                <a
                  href="https://apps.apple.com/in/app/demand-harvest/id6496201989"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={appTop}
                    className="img-fluid appStoreTop"
                    alt="App Store"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mx-auto  about_container">
        <div class="row">
          <div class="col-lg-6 text-center">
            <div className="text-center-about-1">
              <p>About-us</p>
            </div>
            <div className="firts_para_content">
              <p>
                At Demand Harvest, we're a fledgling enterprise led by a team of
                seasoned professionals boasting 25 years of collective expertise
                in horticulture, flower culture, fruit cultivation, and
                processing. This wealth of knowledge forms the cornerstone of
                our commitment to pioneering advancements in agriculture and
                producing excellence.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 text-center">
            <div className="text-center-about-1">
              <p>What we do ?</p>
            </div>
            <div className="second_para_content">
              <p>
                Demand harvest is core dedicated activity will be supplying
                fresh vegetables at a reasonable price, with quality controlled
                and well planned logistic to reach consumer at nominal time.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 ">
            <div className="text-center-about-1">
              <p>Our insight</p>
            </div>
            <div className="third_para_content">
              <p>
                Demand Harvest in association with local farmers to produce more
                quality organic vegetables with our advance technical training
                center where we train volunteered farmers.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container para_container">
        <div class="row align-items-start">
          <div class="col-lg-6 col-md-6 order-lg-2 order-md-2 order-sm-1">
            <div class="image-container">
              <img
                src={mobileImg1}
                className="img-fluid mobile_First_img"
                alt="MobilePicture"
              />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 order-lg-1 order-md-1 order-sm-2">
            <div className="para_content">
              <p>
                Unlock the flavors of nature with our exclusive grains booking!
                Reserve your seat at the table of health and taste, where every
                grain tells a unique story of nourishment and culinary delight.
                Join us in savoring the essence of wholesome goodness, one
                booking at a time.
              </p>
              <div className="bottom_btn">
                <a
                  href="https://play.google.com/store/apps/details?id=com.demandharvest.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={bottomGoogle}
                    className="img-fluid googlePlayBottom"
                    alt="Google Play"
                  />
                </a>
                <a
                  href="https://apps.apple.com/in/app/demand-harvest/id6496201989"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={bottomApp}
                    className="img-fluid appStoreBottom"
                    alt="App Store"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container bottom_box_container">
        <div class="row">
          <div class="col-lg-6 text-center">
            <div className="bottom_mobile_image">
              <img
                src={mobileImg2}
                class="img-fluid "
                alt="BackgroundPicture"
              />
            </div>
            <div class="bottom-container">
              <div class="row">
                <div class="col-lg-6 text-center">
                  <div className="address">
                    <p>Address</p>
                    <p>
                      No 8/1, Banashankari Krupa, 9th East Hongasandra Main
                      Road, Sri Ram Nagar,{" "}
                    </p>
                    <p>Bengaluru, Karnataka, 560068</p>
                  </div>
                </div>
                <div class="col-lg-6 text-center">
                  <div className="contact">
                    <p>Contact Number</p>
                    <p>+91 8867064507</p>
                  </div>
                </div>
                <div class="col-lg-6 text-center">
                  <div className="privacy">
                    <a href="/privacy-policy" className="link_privacy"  target="_blank">
                      <p>Terms & Conditions - Privacy policy</p>
                    </a>
                  </div>
                </div>
                <div class="col-lg-6 text-center">
                  <div className="copy">
                    <p>Copyright © 2021 All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showContactDetails && <ContactDetails onClose={toggleContactDetails} />}
    </div>
  );
};

export default LandingPage;
