import React, { useState } from "react";
import '../contact/contact.css';
import contactImage from '../../images/contact-image.png';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";

export const ContactDetails = ({ onClose }) => {
    const [modal, setModal] = useState(true);
    const [errors, setErrors] = useState({});
    const [successMsg, setSuccessMsg] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const toggleModal = () => {
        setModal(!modal);
        onClose();
        setSuccessMsg(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validate = () => {
        let tempErrors = {};
        if (!formData.name) {
            tempErrors.name = "Name is required.";
        } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
            tempErrors.name = "Invalid Name.";
        }
        if (!formData.email) {
            tempErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "Invalid E-mail.";
        }
        if (!formData.phone) {
            tempErrors.phone = "Phone number is required.";
        } else if (!/^\d{7,}$/.test(formData.phone)) {
            tempErrors.phone = "Invalid Phone Number";
        }
        if (!formData.message) {
            tempErrors.message = "Message is required.";
        }
        return tempErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            try {
                 await axios.post('https://us-central1-gee-dee-dev.cloudfunctions.net/contact_us', {
                    fullName: formData.name,
                    email: formData.email,
                    phone: formData.phone,
                    message: formData.message
                });
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
                setErrors({});
                setSuccessMsg(true);
            } catch (error) {
                console.log("Error submitting the form: ", error);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <>
            <div className="contact_container">
                {modal && (
                    <div className="modal-overlay">
                        <div className="contact-container-details">
                            <div className="contact-details-us">Contact us</div>
                            <div className="icon">
                                <CloseIcon className="close-modal" onClick={toggleModal} />
                            </div>
                            <div className="name-text">Enter name<span className="red-star">&#9733;</span></div>
                            <div>
                                <input 
                                    className="name-input" 
                                    placeholder="Name" 
                                    name="name" 
                                    value={formData.name} 
                                    onChange={handleChange} 
                                />
                                {errors.name && <div className="nameError">{errors.name}</div>}
                            </div>
                            <div className="email-text">Email<span className="red-star">&#9733;</span></div>
                            <div>
                                <input 
                                    className="email-input" 
                                    placeholder="Email" 
                                    name="email" 
                                    value={formData.email} 
                                    onChange={handleChange} 
                                />
                                {errors.email && <div className="emailError">{errors.email}</div>}
                            </div>
                            <div className="flower_Image">
                                <img src={contactImage} alt="Contact-US" />
                            </div>
                            <div className="phone-text">Enter phone number<span className="red-star">&#9733;</span></div>
                            <div>
                                <input 
                                    className="phone-input" 
                                    placeholder="Phone Number" 
                                    name="phone" 
                                    value={formData.phone} 
                                    onChange={handleChange} 
                                />
                                {errors.phone && <div className="phoneError">{errors.phone}</div>}
                            </div>
                            <div className="msg-text">Message here<span className="red-star">&#9733;</span></div>
                            <div>
                                <textarea 
                                    className="msg-input" 
                                    name="message" 
                                    value={formData.message} 
                                    onChange={handleChange} 
                                />
                                {errors.message && <div className="msgError">{errors.message}</div>}
                            </div>
                            <div>
                                <button className="send-btn" onClick={handleSubmit}>Send</button>
                                {successMsg && <div className="success-message">Thank you for contacting us!</div>}

                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
