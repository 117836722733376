import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LandingPage } from "./components/landingPage/LandingPage";
// import PrivacyPolicy from "../src/components/privacyPolicy/PrivacyPolicy.js";
const PrivacyPolicy = lazy(
  () => import("../src/components/privacyPolicy/PrivacyPolicy.js"),
  { ssr: false }
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
